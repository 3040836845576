.counsellingForm .formInput {
  margin-bottom: 30px; }
  .counsellingForm .formInput input,
  .counsellingForm .formInput textarea,
  .counsellingForm .formInput select {
    background: white;
    height: 50px;
    padding: 6px 15px;
    color: #666666;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5); }
    .counsellingForm .formInput input:focus,
    .counsellingForm .formInput textarea:focus,
    .counsellingForm .formInput select:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #c0b596; }
    .counsellingForm .formInput input::-webkit-input-placeholder,
    .counsellingForm .formInput textarea::-webkit-input-placeholder,
    .counsellingForm .formInput select::-webkit-input-placeholder {
      color: #bbb5a5;
      font-size: 14px; }
  .counsellingForm .formInput select {
    color: #666666;
    position: relative;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background: white url(../../images/select-icon.png) no-repeat calc(100% - 15px) center; }
  .counsellingForm .formInput textarea {
    height: 150px;
    padding: 15px; }
  .counsellingForm .formInput p {
    /*   text-transform: uppercase; */
    font-size: 12px;
    color: red; }

.counsellingForm .formInput1 {
  margin-bottom: 30px; }
  .counsellingForm .formInput1 input,
  .counsellingForm .formInput1 textarea,
  .counsellingForm .formInput1 select {
    background: #F3F0EA;
    height: 50px;
    padding: 6px 60px !important;
    color: #666666;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5); }
    .counsellingForm .formInput1 input:focus,
    .counsellingForm .formInput1 textarea:focus,
    .counsellingForm .formInput1 select:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #c0b596; }
    .counsellingForm .formInput1 input::-webkit-input-placeholder,
    .counsellingForm .formInput1 textarea::-webkit-input-placeholder,
    .counsellingForm .formInput1 select::-webkit-input-placeholder {
      color: #bbb5a5;
      font-size: 14px; }
  .counsellingForm .formInput1 select {
    color: #666666;
    position: relative;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center; }
  .counsellingForm .formInput1 textarea {
    height: 150px;
    padding: 15px; }
  .counsellingForm .formInput1 p {
    /*    text-transform: uppercase; */
    font-size: 12px;
    color: red; }

.counsellingForm button {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 9px 25px;
  border: 2px solid transparent;
  text-transform: capitalize;
  display: inline-block;
  transition: all .3s;
  background: #c0b596; }
  .counsellingForm button:hover {
    background: #d4c291; }

.react-tel-input .selected-flag {
  width: 50px !important; }

.react-tel-input .form-control {
  width: 100% !important; }

.client-file-area-left-side {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.client-file-area-left-side p {
  font-size: 15px;
  font-weight: 550;
  color: #282e3f; }

.signon-bottom-area1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; }

.client-file-area-left-side1 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }

.client-file-area-left-side1 p {
  font-size: 15px;
  font-weight: 550;
  color: #282e3f; }

/* checkbox */
.styled-checkbox {
  position: absolute;
  opacity: 0;
  /*  &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    } */ }
  .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    background: white;
    border: 1px solid #c0b596; }
  .styled-checkbox:hover + label:before {
    background: white;
    border: 1px solid #c0b596; }
  .styled-checkbox:checked + label:before {
    background: #c0b596; }
  .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd; }
  .styled-checkbox:checked + label:after {
    content: '';
    position: absolute;
    left: 9px;
    top: 13px;
    background: white;
    width: 4px;
    height: 4px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg); }

.title {
  text-align: center;
  color: #4571ec; }

.checkbo-label-A {
  color: #282e3f;
  font-size: 16px;
  font-weight: 550;
  padding-right: 10px; }

.clear-button {
  background-color: #c0b596;
  padding: 10px 15px 10px 15px;
  color: white;
  cursor: pointer;
  border-radius: 5px; }

.customer-file-loader-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px; }

.caseFiletopAreaHeaderREceipnest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }

/* font-family: 'Open Sans', sans-serif; */
.caseFiletopAreaHeaderREceipnest h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px; }

@media (max-width: 7000px) and (min-width: 600px) {
  .caseFiletopAreaHeaderREceipnest img {
    width: 250px; } }

@media (max-width: 600px) and (min-width: 0px) {
  .caseFiletopAreaHeaderREceipnest img {
    width: 200px; } }

.caseFiletopAreaHeaderb {
  margin-top: -20px !important;
  margin-bottom: -25px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

.caseFiletopAreaHeaderb p {
  font-size: 20px;
  font-weight: 600;
  color: #282e3f !important;
  /* text-transform:uppercase */ }

@media (max-width: 7000px) and (min-width: 991px) {
  .date-label-deisgin-box {
    border: 1px solid #c0b596;
    font-size: 16px;
    padding: 2px 6px !important; } }

@media (max-width: 991px) and (min-width: 768px) {
  .date-label-deisgin-box {
    border: 1px solid #c0b596;
    font-size: 14px;
    padding: 2px 6px !important; } }

@media (max-width: 768px) and (min-width: 0px) {
  .date-label-deisgin-box {
    border: 1px solid #c0b596;
    font-size: 14px;
    padding: 2px 6px !important; } }

.checkbow-style-areafile {
  display: flex;
  justify-content: flex-start; }

.receptionist-note-add-file {
  background: white;
  padding: 15px;
  color: #666666;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(192, 181, 150, 0.5);
  font-weight: 550;
  margin-bottom: 30px; }

.receptionist-note-add-file p {
  margin-bottom: 0px !important; }

.receptionist-note-add-customer {
  height: 85%;
  background: white;
  color: #666666;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(192, 181, 150, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }

.receptionist-note-add-customer p {
  font-size: 16px;
  font-weight: 600;
  color: #666666;
  cursor: pointer; }

.floatffff {
  z-index: 100000;
  position: fixed;
  width: 140px;
  height: 40px;
  bottom: 40px;
  left: 70px;
  background-color: #353535;
  color: #FFF;
  border-radius: 20px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  justify-content: center;
  align-items: center; }

.my-floatffff {
  margin-top: 22px;
  font-weight: 600;
  font-size: 18px; }
