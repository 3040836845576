.admin-cutomer-profile-area{
    min-height: auto !important;
    background-color:#F9F8F8 !important;
    border-left: 1px solid lightgrey;
  /*   -webkit-box-shadow: -4px 2px 10px 0px rgba(214,207,214,1);
-moz-box-shadow: -4px 2px 10px 0px rgba(214,207,214,1);
box-shadow: -4px 2px 10px 0px rgba(214,207,214,1); */

}
.admin-side-bar-area{
    height: 100%;
    background-color: white !important;
   
         

    -webkit-box-shadow: 3px 2px 10px 0px rgba(214,207,214,1);
    -moz-box-shadow: 3px 2px 10px 0px rgba(214,207,214,1);
    box-shadow: 3px 2px 10px 0px rgba(214,207,214,1);
}
.adminPanelBackarea{
    background-color: white !important;   
  
}
@media(max-width:7000px) and (min-width:991px){
    .admin-cols{
        padding-left: 0px !important;
        padding-right: 0px !important;
        background-color: white !important;   
   
    }
    .admin-cols1{
padding-left: 0px !important;
/* padding-right: 0px !important; */
        background-color: white !important;   
    }
}
@media(max-width:991px) and (min-width:0px){
    .admin-cols{
        padding-right: 0px !important;
        padding-left: 0px !important;
        background-color: white !important;   
    }
    .admin-cols1{
        /* padding-right: 0px !important; */
        padding-left: 0px !important;
        background-color: white !important;   
    }
}
.admin-profile-area{
    padding:50px 0px 30px 0px;
}
.admin-profile-area center img{
border-radius: 50%;
}
.admin-cutomer-profile-area-top{
    padding: 20px 30px 30px 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.search-area-admin-top{
    margin-top: 10px;
    background-color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    -webkit-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
-moz-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
}
.input-search-area-admin{
    border: none;
}
.input-search-area-admin:focus{
    border: none !important;
    outline: none; 
}
.admin-cutomer-profile-area-add-client{
    margin-top: 10px;
    background-color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    -webkit-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
-moz-box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);
box-shadow: -1px 2px 10px 0px rgba(214,207,214,1);   
cursor: pointer;
}
.admin-cutomer-profile-area-add-client p{
    margin-bottom: 0px;
    font-size: 14px;
}


@media(max-width:7000px) and (min-width:991px){
    .client-profile-details-area{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 10px;
        margin-left: 8px;
      /*   display: flex;
        justify-content: center; */
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    .admin-cutomer-deetail-profile-area{
        margin-left: 30px;
        -webkit-box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
        -moz-box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
        box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
    /* border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey; */
    height: auto;
    background-color: white;
  
    
    }
}
@media(max-width:991px) and (min-width:0px){
    .client-profile-details-area{
     width: 100%;
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 10px;
      /*   display: flex;
        justify-content: center; */
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    .admin-cutomer-deetail-profile-area{
      
        -webkit-box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
        -moz-box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
        box-shadow: 0px 2px 10px 0px rgba(214,207,214,1);
    /* border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey; */
    height: auto;
    background-color: white;
    padding-bottom: 30px;
    
    }
}
.profile-image-area-admin145{

height: 120px;
width: 120px;
}
.profile-image-area-admin145img{
    background-color: white;
    border: 1px solid lightgrey;
    padding: 2px;
    width: 30px;
    cursor: pointer;
    border-radius: 50%;
}
.update-profile-pic-admin-icons189{
  
 text-align: right;
margin-top: -30px;
margin-right: 10px;
}
.client-profile-details-area-bottom-area{
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap;
  width: 90%;
    margin-top: 40px;
}
.client-profile-details-area-bottom-area p{
font-weight: 550;
font-size: 14px;
margin-bottom: 0px;
cursor: pointer;
}
.rating-area-admin{
    width: 120px;
    padding-bottom: 3px;
    border-bottom: 6px solid green;

}
.colsbor-area-admin{
    border-right: 1px solid lightgrey;
}
.cutomer-details1455-prfile-area{
    height: 110px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px 0px 10px;
}
.cutomer-details1455-prfile-area1{
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px 0px 10px;
}
.cutomer-details1455-prfile-area-content p{
    margin-bottom: 0px;
}
.side-bar-collapase{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
}
/* #D4C291 */
.side-bar-collapase i{
font-size: 30px;
color:#D4C291 ;
cursor: pointer;
font-weight: bold;
animation: all 5s;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    background-color: #F5F5F5;
    overflow-x: hidden;
    transition: 0.5s;
 
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: -2px;
    right: 0px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  @media(max-width:7000px) and (min-width:991px){
      .sidenav2{
          display: none !important;
      }
  }
  @media(max-width:991px) and (min-width:0px){
    .sidenav1{
        display: none !important;
    }
    .sidenav2{
        margin-top: -56px !important;
        margin-bottom: 20px !important;
    }
}
@media(max-width:7000px) and (min-width:2000px){
    .sidenav4{
        display: none !important;
    }
}
@media(max-width:2000px) and (min-width:0px){
  .sidenav3{
      display: none !important;
  }
  .sidenav4{
      margin-top: -56px !important;
      margin-bottom: 20px !important;
  }
}
.active-Links-accountant{
    background: rgb(223, 223, 223) !important;
    color: #201d20 !important;
    
}